<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <ykc-form-item class="file-error" label="选择文件" prop="file">
      <ykc-upload
        required
        ref="file"
        type="xlsx"
        :size="5"
        :temText="linkText"
        :temhref="modelUrl"
        :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
        @handleChange="fileUpload"
        @handleRemove="handleRemove"></ykc-upload>
    </ykc-form-item>
  </ykc-form>
</template>

<script>
  import { filterByIdList } from '@/utils/index';
  import { stationPage } from '@/service/apis';

  export default {
    data() {
      return {
        rules: {
          discountType: [{ required: true, message: '请选择折扣类型', trigger: 'blur' }],
          file: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                if (this.$refs.file.fileList.length > 0) {
                  callback();
                } else {
                  callback(new Error('请选择需要导入的文件'));
                }
              },
            },
          ],
        },
        ruleForm: {
          discountType: '1',
          file: undefined,
        },
      };
    },
    computed: {
      modelUrl() {
        if (this.ruleForm.discountType === '1') {
          return `${process.env.VUE_APP_OSS}/finance/upload/直联电站导入模板_统一折扣.xlsx`;
        }
        if (this.ruleForm.discountType === '2') {
          return `${process.env.VUE_APP_OSS}/finance/upload/直联电站导入模板_分时折扣.xlsx`;
        }
        return ``;
      },
      linkText() {
        if (this.ruleForm.discountType === '1') {
          return '直联电站导入模板.xlsx';
        }
        if (this.ruleForm.discountType === '2') {
          return '直联电站导入模板.xlsx';
        }
        return '';
      },
    },
    created() {},
    methods: {
      filterByIdList,
      fileUpload(file, fileList, isCheck) {
        if (isCheck) {
          this.ruleForm.file = file.raw;
        }
        this.$refs.ruleForm.validateField('file');
      },
      handleRemove() {
        this.ruleForm.file = undefined;
      },
      submitForm() {
        return new Promise(resolve => {
          this.validateForm().then(() => {
            stationPage.import(this.ruleForm).then(() => {
              this.$message({
                message: '批量导入成功',
                type: 'success',
              });
              resolve();
            });
          });
        });
      },
      /** 校验当前表格是否通过 */
      validateForm() {
        return new Promise(resolve => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              resolve();
            }
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
